<ds-metadata-field-wrapper [label]="label | translate">
    <span class="dont-break-out" *ngFor="let mdValue of mdValues; let last=last;">
       
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by/2.5/ar/'" href="http://creativecommons.org/licenses/by/2.5/ar/"><img src="https://licensebuttons.net/l/by/3.0/88x31.png" alt="MDN"></a>
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by-sa/2.5/ar/'" href="http://creativecommons.org/licenses/by-sa/2.5/ar/"><img src="https://licensebuttons.net/l/by-sa/3.0/88x31.png" alt="MDN"></a>
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by-nd/2.5/ar/'" href="http://creativecommons.org/licenses/by-nd/2.5/ar/"><img src="https://licensebuttons.net/l/by-nd/3.0/88x31.png" alt="MDN"></a>
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by-nc/2.5/ar/'" href="http://creativecommons.org/licenses/by-nc/2.5/ar/"><img src="https://licensebuttons.net/l/by-nc/3.0/88x31.png" alt="MDN"></a>
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by-nc-sa/2.5/ar/'" href="http://creativecommons.org/licenses/by-nc-sa/2.5/ar/"><img src="https://licensebuttons.net/l/by-nc-sa/3.0/88x31.png" alt="MDN"></a>
        <a *ngIf="mdValue.value=='http://creativecommons.org/licenses/by-nc-nd/2.5/ar/'" href="http://creativecommons.org/licenses/by-nc-nd/2.5/ar/"><img src="https://licensebuttons.net/l/by-nc-nd/3.0/88x31.png" alt="MDN"></a>
        <div *ngIf="label!=='item.page.licence'"> {{mdValue.value}}<span *ngIf="!last" [innerHTML]="separator"></span></div>
    </span>
</ds-metadata-field-wrapper>
