<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'publication.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <!--ds-generic-item-page-field [item]="object" 
      [fields]="['dc.date']" 
      [label]="'item.page.date'">
    </ds-generic-item-page-field-->
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <!--ds-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'publication.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'publication.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'publication.page.volume-title'">
    </ds-generic-item-page-field-->
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.rights']"
      [label]="'item.page.licence'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPublication'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalIssueOfPublication'"
      [label]="'relationships.isJournalIssueOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'publication.page.description'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="','"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
      <a class="btn btn-outline-primary" role="button" [routerLink]="['/statistics/'+itemPageRoute ]">
         Estadísticas
      </a>
    </div>
  </div>
</div>
