<!-- Site footer -->
<footer class="site-footer">
  <div class="container" style="margin-top: 20px;">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5>Contacto</h5>
        <p class="text-justify">Puede contactar con el administrador de RIDAA en</p>
        <ul class="footer-links">
          <li><span>Teléfono: (54) (0) (249) 4385502</span> </li>
          <li><span>Correo electrónico:</span> <a href="ridaa@rec.unicen.edu.ar"> ridaa@rec.unicen.edu.ar</a></li>

        </ul>
      </div>
      <div class="col-xs-6 col-md-3">
        <h5>Información</h5>
        <ul>
          <li>
            <a target="_blank" href="/assets/pdf/RJ823222 _aprobación_del_ridaa_y_las_políticas_AA.pdf">Resolución de Creación</a>
          </li>
          <li>
            <a target="_blank" href="http://creativecommons.org/licenses/">Tipo de licencias</a>
          </li>
          <li>
            <a target="_blank" href="https://www.ridaa.unicen.edu.ar/carga_ri/cargar.xhtml">Carga</a>
          </li>
        </ul>
      </div>

      <div class="col-xs-6 col-md-3">
        <h5>Documentación Links</h5>
        <ul>
          <li>
            <a target="_blank" href="/assets/pdf/Autorizacion_RIDAA.pdf">Licencia autores</a>
          </li>
          <li>
            <a target="_blank" href="/assets/pdf/Tutorial_llenado_Licencia_autoresRIDAA.pdf">Tutorial Licencia autores</a>
          </li>
        </ul>
      </div>
    </div>
    <hr>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-6 col-xs-12">
        <p class="m-0">
          <a class="text-white" href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
          {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
          <a class="text-white" href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
        </p>
        <p class="m-0">
          <a class="text-white" href="javascript:void(0);" (click)="showCookieSettings()">{{ 'footer.link.cookies' |
            translate}} </a>
          <a class="text-white" routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}} </a>
          <a class="text-white" routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' |
            translate}} </a>
          <a class="text-white" routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </p>

      </div>

      <div class="col-md-4 col-sm-6 col-xs-12">
        <p class="m-0" style="float: right;">
          <a href="https://www.facebook.com/unicen.bibliotecacentral" class="fab fa-facebook fa-lg fa-fw"></a>
          <a href="https://twitter.com/renovbiblio" class="fab fa-twitter fa-lg fa-fw"></a>
        </p>
      </div>
    </div>
  </div>

</footer>